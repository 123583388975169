/* Mixins */
.energy-index-component {
  min-width: 1236px !important;
}
.energy-index-component .page-body-margin {
  margin: 16px;
}
.energy-index-component .energy-electricity-item-interval-type-switch {
  position: absolute;
  top: 50px;
  right: 20px;
}